<template>
  <a-modal 
    :width="600" 
    :visible="visible" 
    title="权限配置"
    @ok="handleOk"
    @cancel="handleCancel"
    >
    <template slot="footer" class="footer_btn">
      <a-button @click="handleCancel">取消</a-button>
      <a-button class="okBtn" @click="handleOk">确定</a-button>
    </template>
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input disabled v-decorator="['name']" placeholder="请输入名称" ></a-input>
        </a-form-item>
        <a-form-item label="用户" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['suername']" placeholder="请选择用户" >
            <a-tooltip slot="suffix" title="选择">
              <a-icon type="menu-unfold" style="color: rgba(0,0,0,.45)" @click="userChange" />
            </a-tooltip>
          </a-input>
        </a-form-item>
        <a-form-item label="权限" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <j-select-multiple  v-model="jselectMultiple.value" placeholder="请选择权限" :options="jselectMultiple.options"/>
        </a-form-item>
      </a-form>
    </a-spin>
    <change-user-modal ref="userModal" @ok="changeUser" />
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'
import JSelectMultiple from '@/components/JSelectMultiple'
import ChangeUserModal from './ChangeUserModal'
export default {
  components: {
      JSelectMultiple,
      ChangeUserModal
    },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      model: {},
      jselectMultiple: {
          options: [
            { text: '管理', value: 'guanli' },
            { text: '查看', value: 'chakan' }
          ],
          value: ''
        },
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 }
      },
      form: this.$form.createForm(this),
    }
  },
  methods: {
    add(record) {
      console.log('record: ', record);
      this.visible=true
      this.form.resetFields();
      this.model = Object.assign({}, record);
      this.jselectMultiple.value = record.warrantyCode
      this.$nextTick(() => {
        this.form.setFieldsValue(pick(this.model,'name'))
      })
    },
    userChange() {
      this.$refs.userModal.edit()
    },
    changeUser(value) {
      console.log('value: ', value);
    },
    handleOk() {
      const that = this;
      this.form.validateFields((err, values) => {
        if (!err) {
          that.confirmLoading = true;
          let formData = Object.assign(this.model, values);
          console.log('formData: ', formData);
        }
      })
    },
    handleCancel() {
      this.visible=false
    }
  }
}
</script>
<style lang="less" scoped>
.okBtn{
  color: #fff;
  background: #BC0039;
}
</style>


