<template>
  <a-modal
    v-if="visible"
    title="预览"
    width="1000px"
    :visible="visible"
    @cancel="handleCancel"
    :footer="null"
  >

  <a-spin :spinning="spinning">
    <pdf class="pdf"
      v-for="i in numPages"
      :key="i"
      ref="pdf"
      :page="i"
      style="width: 100%; height: auto;"
      @num-pages="pageTotalNum=$event"
      :src="pdfUrl">
    </pdf>
    <!-- <div v-for="(img, index) in  imgData" :key="index">
      <img :src="'data:image/png;base64,'+img">
    </div> -->
  </a-spin>
  </a-modal>
  <a-modal
    v-else-if="visible2"
    title="预览"
    width="1000px"
    :visible="visible2"
    @cancel="handleCancel"
    :footer="null"
  >

    <img :src="imgUrl" width="100%"/>
  </a-modal>
  <a-modal
    v-else
    title="预览"
    width="1650px"
    :visible="video"
    @cancel="handleCancel"
    :footer="null"
  >
    <video @contextmenu.prevent="" id="video" ref="video" width="1600" height="900"
           class=" vjs-default-skin vjs-big-play-centered"
           controls controlslist="nodownload" muted poster="">
      <source :src="filepath"
              type='video/mp4'>
    </video>
  </a-modal>
</template>
<script>
import { postAction } from '@/api/manage'
import pdf from 'vue-pdf'

export default {
  components: {
    pdf
  },
  data() {
    return {
      officeUrl:'',
      visible: false,
      spinning: false,
      filepath:'',
      video:false,
      numPages:1,
      imgUrl:'',
      src:'',
      pageTotalNum:1,
      pdfUrl:'',
      staticPdfUrl:window._CONFIG['staticPdfUrl'],
      staticDomainURL: window._CONFIG['staticDomainURL'],
      imgData:[],
      visible2:false,
    }
  },
  methods: {
    async loadPdfHandler () {
      //src为你服务器上存放pdf的路径
      this.pdfUrl = ''
      this.video = false;
      this.visible = true;
      this.visible2 = false;
      console.log(this.model);
      if(this.src.indexOf('resourceCenter')!= -1) {
        this.pdfUrl = pdf.createLoadingTask(this.staticPdfUrl+this.src);
        this.pdfUrl.promise.then(pdf => {
          this.numPages = pdf.numPages
        })
        return
      }
      if(this.src.indexOf("home")!=-1){
        this.pdfUrl = pdf.createLoadingTask(this.staticPdfUrl+this.src);
        this.pdfUrl.promise.then(pdf => {
          this.numPages = pdf.numPagesz
        })
      }else {
        this.pdfUrl = pdf.createLoadingTask(this.staticDomainURL+this.src);
        this.pdfUrl.promise.then(pdf => {
          this.numPages = pdf.numPages
        })
      }
    },
    startVideo(record){
      this.filepath = ''
      console.log(this.staticDomainURL+(record+'').substring(1))
      //this.filepath = this.staticDomainURL+(record+'').substring(1);
      this.filepath = this.staticPdfUrl+record
      this.video = true;
      this.pdfUrl = '';
      this.officeUrl = '';
      this.visible = false;
      this.visible2 = false;
      const videoPlay = this.$refs.video;
      if(videoPlay){
         videoPlay.load();
      }
    },
    viewImg(){
      this.imgUrl = ''
      console.log(this.imgUrl)
      var dates = this.model.createTime
      var times = new Date(dates).getTime();
      if(times<new Date('2022-03-31 18:00:00').getTime()){
        this.imgUrl = this.staticPdfUrl + this.model.filepath;
      }else {
        this.imgUrl = this.staticPdfUrl + this.model.viewpath;
      }
      this.video = false;
      this.visible = false;
      this.visible2 = true;
    },
    add(record) {
      // this.pdfUrl = ''
      this.visible = true
      this.model = Object.assign({}, record)
      console.log('this.model: ', this.model)
      var dates = this.model.createTime
      var times = new Date(dates).getTime();
      if(times<new Date('2021-04-14 18:00:00').getTime()){
        this.src = this.model.filepath;
      }else {
        this.src = this.model.viewpath;
      }
      if(record.filename.indexOf('pdf')!= -1||record.filename.indexOf('PDF')!= -1 ){
        this.loadPdfHandler()
      }else if(record.filename.indexOf('mp4')!= -1 ){
        this.startVideo(this.model.filepath)
      }else{
        this.viewImg()
      }


      // this.loadImg()
    },
    loadImg() {
      this.imgData = [];
      this.spinning = true
      postAction('/mesnac/sys/trans/import',{outFile: this.model.filepath }).then(res => {
        if(res.success==undefined){
          this.imgData = res
          this.spinning = false
        }else{
          console.log(res.success)
          alert("该文件为加密文件，请下载后查看")
          this.spinning = false
          this.imgData = []
          this.visible = false
        }
      })
    },
    handleCancel(){
      this.pdfUrl = '';
      this.officeUrl = '';
      this.filepath = '';
      this.visible = false;
      this.visible2 = false;
      this.video = false;
      const myVideo = this.$refs.video;
      if(myVideo){
         myVideo.pause()
      }
      //let myVideo = document.getElementById('video');    //对应video标签的ID

    }
  }
}
</script>

<style lang="less" scoped>

</style>


