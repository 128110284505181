<template>
  <a-modal
    width="1000px" 
    :visible="visible" 
    title="用户选择"
    @ok="handleOk"
    @cancel="handleCancel"  
  >
    <template slot="footer" class="footer_btn">
      <a-button @click="handleCancel">取消</a-button>
      <a-button class="okBtn" @click="handleOk">确定</a-button>
    </template>
    <div class="user_warp">
      <div style="margin-bottom: 50px; text-align: center;">
        <a-input-search class="input_search" v-model="queryParam.realname" placeholder="请输入用户,电话,邮箱查询" @search="onSearch" />
        <a-button
          class="search_btn search_query"
          style="margin-left: 8px"
          type="primary"
          @click="searchQuery"
        >查询</a-button>
        <a-button
          class="search_btn"
          style="margin-left: 8px"
          @click="searchReset"
        >重置</a-button>
      </div>
      <a-spin :spinning="spinning">
        <!-- <a-table
            ref="table"
            rowKey="userId"
            size="middle"
            :rowSelection="{selectedRowKeys: selectedRowKeys,onChange: onSelectChange}"
            :columns="columns"
            :data-source="dataSource"
            :pagination="ipagination"
            :loading="loading"
            @change="handleTableChange"
          >
            <a slot="name" slot-scope="text">{{ text }}</a>
        </a-table> -->
        <a-checkbox-group v-model="value" style="width: 100%;" @change="onChange">
          <a-row class="radio_row" :gutter="10">
            <a-col v-for="item in userList" :key="item.id" :span="12" style="margin-bottom: 6px;">
              <a-checkbox  :value="item.username">
                {{  item.realname }}
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-spin>
    </div>
  </a-modal>
</template>
<script>
import { getAction } from '@/api/manage'
import { JeecgListMixin } from '@/views/mixins/mixin'
export default {
  mixins: [JeecgListMixin],
  data() {
    return {
      visible: false,
      spinning: false,
      dataSource:[],
      userList:[],
      value: [],
      realname:'',
      selectedRowKeys: [],
      columns: [
        {
          title: '联系人姓名',
          align: 'center',
          dataIndex: 'realname'
        },
        {
          title: '所在部门',
          align: 'center',
          dataIndex: 'departName'
        },
        {
          title: '联系电话',
          align: 'center',
          dataIndex: 'phone'
        },
        {
          title: '联系邮箱',
          align: 'center',
          dataIndex: 'email'
        }
      ],
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    edit(recode) {
      this.visible = true
      console.log('recode: ', recode);
    },
    loadData(pageNum) {
      this.selectedRowKeys = []
      this.selectionRows = []
      if(pageNum == 1 ) {
        this.ipagination.current = 1
      }
      this.loading = true
      let params = this.queryParam
      console.log('params: ', params);
      params.pageSize = 3000
      getAction('/mesnac/sys/user/queryByOrgCodeForAddressList',{pageSize: 3000, ...params}).then(res => {
        if(res.success) {
          this.userList = res.result.records
          // this.dataSource = res.result.records
          // this.ipagination.total = res.result.total
        } else {
          this.$message.warning(res.message)
        }
      }).finally(()=> {
        this.loading = false
      })
    },
    // 搜索
    onSearch() {
      this.loadData(1)
    },
    // 重置
    searchReset() {
      this.realname = ''
      this.queryParam.realname = ''
      this.loadData(1)
    },
    onChange(checkedValues) {
      console.log('checkedValues: ', checkedValues);
      this.value.concat(checkedValues)
      console.log('this.value: ', this.value);
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    handleOk() {
      console.log(this.selectionRows)
      // let suername = ''
      // this.selectionRows.forEach(item => {

      // })
      // this.$emit('ok',this.value)
    },
    handleCancel() {
      this.selectedRowKeys = []
      this.selectionRows = []
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
.okBtn{
  color: #fff;
  background: #BC0039;
}
.user_warp{
  /deep/ .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #BC0039;
    border-right-width: 1px !important;
  }
  .search_btn {
    width: 67px;
    height: 38px;
    margin-top: 5px;
    text-align: center;
    font-size: 16px;
    color: #bc0039;
    border: 1px solid #bc0039;
    border-radius: 10px;
    padding: 0px;
    &.search_query {
      background-color: #ffeded;
    }
  }
}
.input_search{
  width: 500px;
  /deep/ .ant-input {
    border: 1px solid #BC0039;
    color: #BC0039;
    font-size: 14px;
    height: 38px;
    height: 38px;
    &::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: rgba(188, 0, 57, 100);
      font-size: 14px;
    }
    &:focus{
      border: 1px solid rgba(248, 218, 218, 100);
      box-shadow:none;
    }
  }
}
</style>

