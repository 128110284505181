<template>
  <div class="resource_warp">
    <div class="resource-content">
      <div class="resource_right">
        <div class="right_title">
          资源中心
        </div>
        <div class="btn_warp">
          <a-button class="btn" @click="homeJump(file)" v-for="file in fileFirstBtn" :key="file.id">
            {{file.filename}}
          </a-button>
         <!-- <a-button class="btn" @click="homeJump(1)">
           <a-icon class="icon" type="tool" />工具软件
         </a-button> -->
        </div>
      </div>
      <div class="resource_left">
        <!-- 面包屑导航 -->
       <div class="resource_nav">
         <span>文件夹:</span> <span @click="navloade(index)" v-for="(nav,index) in navList" :key="nav.id">| {{nav.filename}} </span>
       </div>
        <div class="search_warp" style="text-align: left" >
          <a-input-search
            style="width: 200px;"
            class="search_input"
            @search="handleSearch"
            placeholder="输入搜索内容"
          />
          <a-button class="commitBtn" style="margin-left: 10px" v-if="isNeedApply" @click="showModal" icon="download">申请下载</a-button>
          <a-button class="commitBtn" v-if="selectedRowKeys.length>0&&!isNeedApply" style="margin-left: 10px" @click="batchDownload" icon="download">批量下载文件</a-button>
        </div>
       <div class="table_warp">
         <a-table
             ref="table"
             rowKey="id"
             size="middle"
             :columns="columns"
             :customRow="rowClick"
             :rowSelection="{selectedRowKeys: selectedRowKeys,onChange: onSelectChange}"
             :data-source="dataSource"
             :pagination="ipagination"
             :loading="loading"
             @change="handleTableChange"
             >
             <span class="table_name" slot="filename" slot-scope="text, record">
               <a-icon class="name_icon" :type="fileType[record.type]" />  {{ text }}
             </span>
             <template slot="action" slot-scope="text, record">
               <a v-if="filePathType(record.filepath) && record.filepath && record.reviewfile == '1' " @click="handleView(record)">查看</a>
               <a v-else style="color:#ccc;">查看</a>
               <a-divider type="vertical" />
               <a v-if="!isNeedApply && record.filepath && record.download == '1' && record.type == 0" @click="handDowload(record)">下载</a>
               <a v-else style="color:#ccc">下载</a>
             </template>
         </a-table>
       </div>
        <!-- <div class="img_warp">
          <img src="~@/assets/img/expect.png" alt="">
        </div>
        <span>暂未开通服务，敬请期待</span> -->
      </div>
    </div>
    <addResources-modal ref="modalForm" @ok="modalFormOk"></addResources-modal>
    <jurisdiction-modal ref="jurisdForm" @ok="modalFormOk" />
    <view-modal ref="viewForm" />
    <a-modal
      title="申请下载"
      :width='600'
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel">
      <template>
        申请原因：
      <br/>
        <a-textarea class="commitBtn" placeholder="请输入" :rows="4" v-model="commitPermisssion"/>
        <template slot="footer">
          <a-button key="back" class="commitBtn" @click="handleCancel">
            取消
          </a-button>
          <a-button key="submit" class="commitBtn" type="primary" :loading="submitLoading" @click="handleOk">
            提交
          </a-button>
        </template>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { JeecgListMixin } from '@/views/mixins/mixin'
import { getAction,postAction, getFileAccessHttpUrl, deleteAction } from '@/api/manage'
import Vue from 'vue'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
import AddResourcesModal from './modules/AddResourcesModal'
import JurisdictionModal from './modules/JurisdictionModal'
import ViewModal from './modules/ViewModal'
const fileType = {1: 'folder-open', xls: 'file-excel', pdf: 'file-pdf', ptt: 'file-ppt', text: 'file-text', word: 'file-word',jpg: 'file-image', png: 'file-image', zip:'file-zip' }
const homeFileList = ['知识文档', '工具软件']

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
export default {
  mixins:[JeecgListMixin],
  components: {
    AddResourcesModal,
    JurisdictionModal,
    ViewModal
  },
  data() {
    return {
      record:'',
      fileType,
      rowSelection,
      nums:-1,
      hiddenSearch:true,
      currentFile:'',
      visible: false,
      confirmLoading: false,
      parentid:'',
      downloadUrl:window._CONFIG['domianURL'],
      flay:'',
      hasChildrenField:"hasChild",
      pidField:"pid",
      expandedRowKeys:[],
      fileFirstBtn: [],
      rootid:"",
      submitLoading:false,
      warrantyData: {},
      commitPermisssion: "",
      loadParent: false,
      openFileDialogOn: false,
      navList:[],
      redirectFilepath:[],
      redirectSign:false,
      isNeedApply:false,
      bizPath:"1358296552120037378",
      userInfo: Vue.ls.get(USER_INFO),
      homeFileList,
      homeFile:'知识文档',
      tokenHeader: {'X-Access-Token': Vue.ls.get(ACCESS_TOKEN)},
      jsbzfilename:'',
      columns:[
        {
          title: '名称',
          align: "left",
          dataIndex: 'filename',
          scopedSlots: { customRender: 'filename' }
        },
        {
          title:'文件大小',
          align:'center',
          width:'100px',
          dataIndex:'filesize',
          customRender: v => this.sizeTrans(v)
        },
        {
          title: '创建者',
          width:'100px',
          align: "center",
          dataIndex: 'createBy'
        },
        {
          width:'100px',
          title: '操作',
          align: "center",
          dataIndex: 'action',
          scopedSlots: {customRender: 'action'}
        }
      ],
      url:{
        list:'/mesnac/zmeResource/zmeResourceCenter/init',
        downloadFiles: "/mesnac/zmeResource/zmeResourceCenter/downloadZipFiles",
        childList: "/mesnac/zmeResource/zmeResourceCenter/selectByParentId",
        childListByJSBZ: "/mesnac/zmeResource/zmeResourceCenter/selectJSBZ",
        getChildListBatch: "/mesnac/zmeResource/zmeResourceCenter/getChildListBatch",
        uploadFile:'/mesnac/zmeResource/zmeResourceCenter/uploadFile',
        getWarranty:'/mesnac/zmeResource/zmeResourceCenter/getWarranty',
        download:'/mesnac/zmeResource/zmeResourceCenter/download',
        commitPermisssion:'/mesnac/zmeResource/zmeResourceCenter/commitPermission',
        PageOfficeUrl:'/mesnac/pageoffice/financeJumpPage',
        decFile:'/mesnac/zmeResource/zmeResourceCenter/decFile',
      }
    }
  },
  computed: {
    uploadFileUrl(){
      return `${window._CONFIG['domianURL']}/${this.url.uploadFile}`;
    },
  },
  created() {
    this.translateUrl()
  },
  methods: {
    loadData(arg){
      console.log('123456')
      if(arg==1){
        this.ipagination.current=1
      }
      this.loading = true
      let params = this.getQueryParams()
      params.hasQuery = 'true'
      this.navList = []
      getAction(this.url.list,params).then(res=>{
        if(res.success){
          this.fileFirstBtn = res.result;
          res.result.forEach(item => {
              if(item.filename == this.homeFile) {
                  this.jsbzfilename = item.filename
                  if (item.filename == "技术标准"){
                      this.getJSBZChildList(item)
                  }else {
                      this.getChildList(item)
                  }
              }
            })
        }else{
          this.$message.warning(res.message)
        }
      }).finally(()=>{
        if(this.redirectSign){
          this.checkUrl()
        }
        this.loading = false
      })
    },
    translateUrl(){
      console.log(this.getParamObj(window.location.href,'filepath'))
      let filepath = this.getParamObj(window.location.href,'filepath')
      if(filepath==null||filepath===''){
        return
      }
      if(filepath.indexOf('%252F')!==-1){
        filepath = filepath.replaceAll("%252F","/")
      }else if(filepath.indexOf('%2F')!==-1){
        filepath = filepath.replaceAll("%2F","/")
      }
      filepath = filepath.replace("/home/","")
      if(filepath&&filepath!==''){
        this.redirectSign = true;
      }
      console.log(filepath)
      let strings = filepath.split("/")
      this.redirectFilepath = strings
    },
    checkUrl(){
      console.log(this.fileFirstBtn)
      console.log(this.redirectFilepath)
      for (let item of this.fileFirstBtn) {
        if(item.id === this.redirectFilepath[0]){
            this.homeJump(item)
            this.redirectFilepath = this.redirectFilepath.slice(1)
            break
        }
      }
    },
    redirectUrl(){
      for (let item of this.dataSource) {
        console.log(item.id)
        console.log(this.redirectFilepath[0])
        if(item.id === this.redirectFilepath[0]){
          this.nums++;
          if(item.type===1) {
            if (item.isneedapply === 'Y') {
              this.isNeedApply = true;
            } else {
              this.isNeedApply = false;
            }
          }
          if (this.jsbzfilename === "技术标准"){
            this.getJSBZChildList(item)
          }else {
            this.getChildList(item)
          }
          this.redirectFilepath = this.redirectFilepath.slice(1)
          break
        }
      }
    },
    getParamObj(url, param){
      const searchIndex = url.indexOf('?');
      const searchParams = url.slice(searchIndex + 1).split('&');
      for (let i = 0; i < searchParams.length; i += 1) {
        const items = searchParams[i].split('=');
        if (items[0].trim().toLowerCase() === param.toLowerCase()) {
          return items[1].trim();
        }
      }
      return null;
    },
    // 点击table 行
    rowClick(record) {
      console.log("rowClicl")
      return {
          on:{
              click:() => {
                  // this.onSeletClick(record, true)
                this.nums++;
                console.log(record);
                if(record.type==1) {
                  if (record.isneedapply == 'Y') {
                    this.isNeedApply = true;
                  } else {
                    this.isNeedApply = false;
                  }
                }
                if (this.jsbzfilename == "技术标准"){
                    this.getJSBZChildList(record)
                }else {
                    this.getChildList(record)
                }
              }
          }
      }
    },
    // 加载子节点
    getChildList(record) {
      this.record = record;
      this.selectedRowKeys = [];
      this.selectionRows = [];
      if(record.type == '1') {
          console.log("this.userid")
          console.log(record.id)
          console.log(this.userInfo.id)
        getAction(this.url.childList, {parentid: record.id, userid:this.userInfo.id}).then(res => {
          if(res.success) {
            this.ipagination.total = Number(res.result.total)
            this.dataSource = res.result
            if(this.navList.length >= 1) {
              if(this.navList[this.navList.length -1].id == record.id) {
                this.navList.push()
              } else {
                this.navList.push(record)
              }
            } else {
              this.navList.push(record)
            }
          }else {
            this.dataSource = []
          }
        }).finally(()=>{
          if(this.redirectSign){
            this.redirectUrl()
          }
          this.loading = false
        })
      }

    },
    // 加载技术标准子节点
    getJSBZChildList(record) {
      this.record = record;
      this.selectedRowKeys = [];
      this.selectionRows = [];
      if(record.type == '1') {
          console.log("this.parentid")
          console.log(record.id)
          console.log("this.userid")
          console.log(this.userInfo.id)
          getAction(this.url.childListByJSBZ, {parentid: record.id, userid:this.userInfo.id}).then(res => {
              if(res.success) {
                  this.ipagination.total = Number(res.result.total)
                  this.dataSource = res.result
                  if(this.navList.length >= 1) {
                      if(this.navList[this.navList.length -1].id == record.id) {
                          this.navList.push()
                      } else {
                          this.navList.push(record)
                      }
                  } else {
                      this.navList.push(record)
                  }
              }else {
                  this.dataSource = []
              }
          })
      }
    },
    //分页、排序、筛选变化时触发
    handleTableChange(pagination, filters, sorter) {
      //TODO 筛选
      if (Object.keys(sorter).length > 0) {
        this.isorter.column = sorter.field;
        this.isorter.order = "ascend" == sorter.order ? "asc" : "desc"
      }
      this.ipagination = pagination;
      // this.loadData();
    },
    batchDownload(){
      console.log(this.selectedRowKeys)
      console.log(this.selectionRows)
      for (let record of this.selectionRows) {
        if(record.download === '1'){
          this.$message.error(`所选文件夹：${record.filename}无下载权限`)
          return;
        }
      }
      let ids = ''
      for (let id of this.selectedRowKeys) {
        ids = id+","+ids
      }
      let userid = this.userInfo.id
      let url = `${window._CONFIG['domianURL']}${this.url.downloadFiles}?ids=${ids}&userid=${userid}`
      console.log('url: ', url);
      var tempwindow=window.open('_blank')
      tempwindow.location=url
    },
    // 面包屑导航
    navloade(num) {
      this.isNeedApply = false
      this.nums = num
      console.log(this.nums)
      this.selectedRowKeys = [];
      this.selectionRows = [];
      if(this.nums!=0){
        console.log(this.navList)
        if(this.navList[this.nums].isneedapply=='Y'){
          this.isNeedApply = true;
        }else{
          this.isNeedApply = false;
        }
      }
      if(num == -1) {
        this.navList = []
        this.loadData(1)
        this.warrantyData = {}
      } else {
        this.navList = this.navList.slice(0,num + 1)
        if (this.jsbzfilename == "技术标准"){
            this.getJSBZChildList(this.navList[num])
        }else{
            this.getChildList(this.navList[num])
        }
      }
    },
    // 按钮跳转
    homeJump(record) {
      console.log(record)
      this.rootid = record.id
      this.homeFile = record.filename
      this.loadData()
      this.nums = 0;
      this.isNeedApply = false;
      this.selectedRowKeys = [];
      this.selectionRows = [];
      },
    handleSearch(record) {
      if(record.length>0){
        getAction(this.url.childList, {parentid: this.record.id, userid:this.userInfo.id, filename:record,rootid:this.rootid}).then(res => {
          if(res.success) {
            this.ipagination.total = res.result.length
            this.dataSource = res.result
            if(this.navList.length >= 1) {
              if(this.navList[this.navList.length -1].id == this.record.id) {
                  this.navList.push()
              } else {
                  this.navList.push(this.record)
              }
            } else {
                this.navList.push(this.record)
            }
          }else {
              this.dataSource = []
          }
        })
      }else {
        this.getChildList(this.record);
      }
    },
    showModal() {
      console.log(this.selectionRows);
      if (this.selectionRows==null||!this.selectionRows.length>0){
        this.$message.warning('请选择文件进行下载申请')
      }else{
        this.visible = true;
      }
    },
    handleOk() {
      this.submitLoading = true;
      this.applyDownload();
    },
    handleCancel() {
      this.visible = false;
    },
    applyDownload(){
      var sysResource = JSON.stringify(this.selectionRows);
      var WJWZ = "/";
      for (let i = 0; i < this.navList.length; i++) {
        WJWZ = WJWZ + this.navList[i].filename+"/";
      }
      console.log(WJWZ);
      postAction(this.url.commitPermisssion, {WJWZ:WJWZ,downloadUrl: this.downloadUrl+this.url.download, employeeNo: this.userInfo.workNo,parentName:this.record.approver,permissionReason:this.commitPermisssion,sysResource}).then(res => {
        if(res.success) {
          this.visible = false;
          this.submitLoading = false;
          this.commitPermisssion = "";
          this.$message.warning('提交申请成功，可在流程中心我的发起处查看')
        }else{
          this.visible = false;
          this.submitLoading = false;
          this.commitPermisssion = "";
          this.$message.warning('数据有误，请联系系统管理员')
        }
      })
    },
    // 预览
    handleView(record) {
       console.log('record: ', record);
      //先解密
      if(record.filename.indexOf('mp4')!= -1 ){
          let type = record.filepath.search(/xlsx|xls|docx|doc|pptx|ppt|XLSX|XLS|DOCX|DOC|PPTX|PPT/);
          if(type != -1) {
          //在线编辑
             window.open(this.url.PageOfficeUrl+"?CurrentHandler=Finance&username=Resource&filename="+record.filename);
          }else {
             this.$refs.viewForm.add(record)
          }
      }else{
          console.log(record.filepath);
          getAction(this.url.decFile, {filepath: record.filepath}).then(res => {
                console.log(res)
          }).finally(()=>{
             let type = record.filepath.search(/xlsx|xls|docx|doc|pptx|ppt|XLSX|XLS|DOCX|DOC|PPTX|PPT/);
             if(type != -1) {
             //在线编辑
                window.open(this.url.PageOfficeUrl+"?CurrentHandler=Finance&username=Resource&filename="+record.filename);
             }else {
                this.$refs.viewForm.add(record)
             }
          })
      }


    },
    // 新增
    handleAdd() {
      console.log(this.selectedRowKeys)
      if(this.selectedRowKeys.length > 1) {
        this.$message.warning('请选择一条数据进行操作!')
      } else {
        if(this.selectedRowKeys.length == 1) {
          getAction(this.url.getWarranty, {resourceId: this.selectionRows[0].id, username:this.userInfo.username}).then(res => {
            if(res.success) {
              this.warrantyData = res.result
              let flay = this.warrantyCode(res.result.warrantyCode)
              if(flay == 1) {
                this.loadParent = true
                let obj = {}
                obj[this.pidField] = this.selectedRowKeys[0]
                console.log('obj: ', obj);
                this.$refs.modalForm.add(obj);
              } else {
                this.$message.warning('您没有权限进行该操作！')
              }
            }
          })
        } else {
          this.$refs.modalForm.add();
          this.$refs.modalForm.title = "新增";
          this.$refs.modalForm.disableSubmit = false;
        }
      }

    },
    // 编辑
    handleEdit() {
      if(this.selectionRows.length != 1) {
        this.$message.warning('请选择一条数据进行操作！')
      } else {
        getAction(this.url.getWarranty, {resourceId: this.selectionRows[0].id, username:this.userInfo.username}).then(res => {
          if(res.success) {
            this.warrantyData = res.result
            let flay = this.warrantyCode(res.result.warrantyCode)
            if(flay == 1) {
              this.$refs.modalForm.edit(this.selectionRows[0]);
              this.$refs.modalForm.title = "编辑";
              this.$refs.modalForm.disableSubmit = false;
            } else {
              this.$message.warning('您没有权限进行该操作！')
            }
          }
        })
      }
    },
    // 上传
    handUpload() {
      if(this.selectionRows.length != 1) {
        this.$message.warning('请选择一条数据进行操作!')
      } else {
        getAction(this.url.getWarranty, {resourceId: this.selectionRows[0].id, username:this.userInfo.username}).then(res => {
          if(res.success) {
            this.warrantyData = res.result
            let flay = this.warrantyCode(res.result.warrantyCode)
            if(flay == 1) {
              this.openFileDialogOn = true
              this.bizPath = this.selectedRowKeys[0]
            } else {
              this.openFileDialogOn = false
              this.$message.warning('您没有权限进行该操作！')
            }
          }
        })
        console.log(this.selectionRows[0])
        // this.bizPath = this.selectedRowKeys[0]
      }
    },
    // 下载
      handDowload(record) {
        console.log('record: ', record);

        let url = `${window._CONFIG['domianURL']}${this.url.download}?id=${record.id}`
        console.log('url: ', url);
        var tempwindow=window.open('_blank')
        tempwindow.location=url
      },
    // 删除
    handDelete() {
      console.log(this.selectedRowKeys)
      if(this.selectedRowKeys.length != 1) {
        this.$message.warning('请选择一条数据进行操作!')
      } else {
        this.$confirm({
          title:'确定要删除吗?',
          icon:'delete',
          className:'deleteModl',
          onOk:() => {
            deleteAction('/mesnac/zmeResource/zmeResourceCenter/delete',{id:this.selectedRowKeys[0]}).then(res => {
              if(res.success) {
                this.$message.success(res.message)
                this.loadData()
              } else {
                this.$message.warning(res.message)
              }
            }).finally(() => {
              this.selectedRowKeys = []
              this.selectionRows = []
            })
          }
        })
      }
    },
    // 权限
    handJurisdiction() {
      console.log('warrantyData', this.warrantyData)
      if(this.selectionRows.length != 1) {
        this.$message.warning('请选择一条数据进行操作!')
      } else {
        this.warrantyData.name = this.selectionRows[0].name
        this.$refs.jurisdForm.add(this.warrantyData)
      }
    },
    // warrantyCode
    warrantyCode(code) {
      if(code) {
        if(code == 'guanli,chakan' || code == 'guanli') {
          return 1
        }
        if(code == 'chakan')  {
          return 2
        }
      } else {
        return false
      }
    },
    modalFormOk() {
      // 新增/修改 成功时，重载列表
      this.loadData();
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    onSeletClick(record, selected) {
      console.log('record: ', record)
      if(selected) {
        getAction(this.url.getWarranty, {resourceId: record.id, username:this.userInfo.username}).then(res => {
          if(res.success) {
            this.warrantyData = res.result
            console.log('this.warrantyData: ', this.warrantyData);
          } else {
            // this.$message.warning(res.message)
          }
        })
      } else {
        this.warrantyData  = {}
      }

    },

    /* 文件下载 */
    // update--autor:lvdandan-----date:20200630------for：修改下载文件方法名uploadFile改为downloadFile------
    downloadFile(text){
      if(!text){
        this.$message.warning("未知的文件")
        return;
      }
      if(text.indexOf(",")>0){
        text = text.substring(0,text.indexOf(","))
      }
      let url = getFileAccessHttpUrl(text)
      console.log('let url: ',url);
      window.open(url);
    },
    uploadClick() {
      if(this.selectionRows.length != 1) {
        this.$message.warning('请选择一条数据进行操作!')
      }
    },
    beforeUpload() {
      if(this.selectedRowKeys.length == '1'){
        this.bizPath = this.selectedRowKeys[0]
      }
    },
    // 文件上传
    handleChange(info) {
        console.log("--文件列表改变--")
        if(!info.file.status && this.uploadGoOn === false){
            info.fileList.pop();
        }
        let fileList = info.fileList
        if(info.file.status==='done'){
            if(this.number>0){
                fileList = fileList.slice(-this.number);
            }
            if(info.file.response.success){
              this.loadData()
                fileList = fileList.map((file) => {
                    if (file.response) {
                        let reUrl = file.response.message;
                        file.url = getFileAccessHttpUrl(reUrl);
                    }
                    return file;
                });
            }
            //this.$message.success(`${info.file.name} 上传成功!`);
        }else if (info.file.status === 'error') {
            this.$message.error(`${info.file.name} 上传失败.`);
        }else if(info.file.status === 'removed'){
            this.handleDelete(info.file)
        }
        this.fileList = fileList
        if(info.file.status==='done' || info.file.status === 'removed'){
            //returnUrl为true时仅返回文件路径
            if(this.returnUrl){
                this.handlePathChange()
            }else{
                //returnUrl为false时返回文件名称、文件路径及文件大小
                this.newFileList = [];
                for(var a=0;a<fileList.length;a++){
                    // update-begin-author:lvdandan date:20200603 for:【TESTA-514】【开源issue】多个文件同时上传时，控制台报错
                    if(fileList[a].status === 'done' ) {
                        var fileJson = {
                            fileName:fileList[a].name,
                            filePath:fileList[a].response.message,
                            fileSize:fileList[a].size
                        };
                        this.newFileList.push(fileJson);
                    }else{
                        return;
                    }
                    // update-end-author:lvdandan date:20200603 for:【TESTA-514】【开源issue】多个文件同时上传时，控制台报错
                }
                this.$emit('change', this.newFileList);
            }
        }
    },
    filePathType(value) {
      if(value) {
          let type = value.search(/pdf|PDF|mp4|png|PNG|jpg|jpeg|xlsx|xls|docx|doc|pptx|ppt/)
          if(type != -1) {
             return true
          } else {
            return false
          }
      } else {
        return false
      }
    },
    sizeTrans(size) {
      if(size) {
        if (size < 1024 * 1024) {
          const temp = size / 1024
          size = temp.toFixed(2)
          return size + 'KB'
        } else {
          const temp = size / (1024 * 1024)
          size = temp.toFixed(2)
          return size + 'MB'
        }
      } else {
        return null
      }

    }
  }
}
</script>

<style lang="less" scoped>
.resource_warp {
  width: 100%;
  min-height: 1000px;
  // background: url("~@/assets/img/home_bg.png") no-repeat top center / cover;
  background: #f6f6f6;
  background-size: 100%;
  padding-top: 25px;
  .resource-content {
    width: 1600px;
    margin: 0 auto;
    background: #fff;
    display: flex;
    .resource_right{
      padding: 35px 15px;
      width: 270px;
      background: #FFECEC 4800%;
      .right_title{
        font-size: 22px;
        color: #101010;
      }
      .search_warp {
        text-align: center;
        .search_input{
          width: 200px;
          margin: 0 auto;
          margin-top: 30px;
          height: 46px;
          /deep/.ant-input{
            height: 46px;
            line-height: 46px;
            border: 1px solid rgba(248, 218, 218, 100);
            border-radius: 20px;
          }
        }
      }
      .title{
        margin-top: 35px;
        font-size: 14px;
        color: #101010;
      }
      .btn_warp{
        margin-top: 20px;
        text-align: center;
        .btn {
          font-size: 18px;
          width: 200px;
          margin: 0 auto;
          margin-bottom: 10px;
          text-align: center;
          transition:none;
          &:hover {
            color: #be002e;
            border: #be002e;
          }
          .icon{
            font-size: 20px;
            color: #be002e;
            vertical-align: text-top;
            margin-right: 20px;
          }
        }
        /deep/[ant-click-animating-without-extra-node='true']::after, .ant-click-animating-node {
          --antd-wave-shadow-color: none;
        }
        /deep/ .ant-btn:active {
          color: #101010;
          font-size: 16px;
          background: #F8DADA;
          border: #be002e;
        }
        /deep/ .ant-btn:focus{
          color: #101010;
          font-size: 16px;
          background: #F8DADA;
          border: #be002e;
        }
      }
    }
    .commitBtn:hover,.commitBtn:focus{
      color:#be002e ;
      border-color:#be002e ;
    }
    .resource_left {
      width: 1300px;
      text-align: center;
      // height: 400px;
      padding-left: 20px;

      .btn_warp{
        padding-top: 20px;
        text-align: left;
        /deep/[ant-click-animating-without-extra-node='true']::after, .ant-click-animating-node {
          --antd-wave-shadow-color: none;
        }
        .btn{
          height: 40px;
          line-height: 40px;
          border-radius: 20px;
          font-size: 16px;
          margin-right: 10px;
        }
        .add {
          background: #bc0039;
          color: #fff;
          border: #bc0039;
        }
        .edit{
          border: 1px solid #bc0039;
          color: #bc0039;
        }
        .jurisdiction{
          border: 1px solid #bc0039;
          color: #bc0039;
          background: #FBE4B7;
        }
        .upload{
          padding: 0 35px;
          border: 1px solid #bc0039;
          color: #bc0039;
          background: #F8DADA;
        }
        .download{
          padding: 0 35px;
          border: 1px solid #252629;
          color: #101010;
          background: #ECF1F9;
        }
        .delete{
          padding: 0 35px;
          border: 1px solid #252629;
          color: #101010;
          background: #FFFFFF;
        }
      }
      .resource_nav{
        text-align: left;
        padding: 20px 10px;
        font-size: 18px;
        color: #101010;
        span {
          font-size: 18px;
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .table_warp{
        margin-top: 20px;
        .table_name{
          font-size: 16px;
          .name_icon{
            margin-right: 3px;
          }
        }
        .downBtn{
          color: #BC0039;
          border-color: #BC0039;
        }
        /deep/.ant-table-thead {
          .ant-checkbox-checked::after{
            border: 1px solid #BC0039;
          }
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #BC0039;
            border: #BC0039;
          }
          .ant-checkbox-indeterminate .ant-checkbox-inner::after {
            background-color: #bc0039;
          }
        }
        /deep/.ant-table-tbody {
          .ant-table-row-expand-icon{
            color: #BC0039;
            border: 1px solid #bc0039;
          }
          .ant-checkbox:hover {
            border: #BC0039;
          }
          .ant-checkbox-checked::after{
            border: 1px solid #BC0039;
          }
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #BC0039;
            border: #BC0039;
          }
        }
        /deep/.ant-pagination {
          .ant-pagination-next, .ant-pagination-prev {
              .ant-pagination-item-link{
                  &:hover{
                    color: #BC0039;
                  }
              }
          }
          .ant-pagination-next:focus .ant-pagination-item-link{
              color: #BC0039;
          }
          .ant-pagination-item{
              a:hover{
                color: #BC0039;
              }
          }
          .ant-pagination-item-active{
              border-color: #BC0039;
              a{color: #BC0039;}
          }
        }
      }
      .img_warp{
        margin: 40px auto;
        height: 200px;
        width: 200px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      span{
        font-size: 14px;
        color: #101010;
      }
    }
  }
}
body /deep/.ant-modal-root .ant-modal{
  .ant-modal-body{
    .ant-modal-confirm-btns{
      .ant-btn-primary{
        background: #bc0039;
      }
    }
  }
}
</style>

